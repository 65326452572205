<template>
  <div class="flex-base invition-box">
    <a class="skip-btn" href="javascript:;" @click="goCompanyCreat">{{ $t("invitationSkipBtn")}}</a>
    <div class="invitation-code">
      <h1>{{ $t("tipsMessage.invitationCode")}}</h1>
      <!--    <div class="flex-row-center captcha_input_wrapper">-->
      <!--      <input-->
      <!--          v-for="(item,index) in captchas"-->
      <!--          :key="index"-->
      <!--          v-model="item.num"-->
      <!--          :id="'captcha'+index"-->
      <!--          @input="inputFinash(index)"-->
      <!--          @focus="adjust(index)"-->
      <!--          @keydown="inputDirection(index)"-->
      <!--          class="captcha_input_box row-center"-->
      <!--          maxlength="1"-->
      <!--      />-->
      <!--    </div>-->
      <div class="flex-row-center captcha_input_wrapper">
        <el-input
          v-for="(item, index) in inputCode"
          :key="index"
          v-model="item.code"
          :id="'testPaste' + index"
          class="captcha_input_box row-center"
          @input="handleInput(index)"
          @keydown.native="keyDown(index)"
          @paste.native="handlePaste()"
        >></el-input>
      </div>
      <el-button
        class="btn-black btn-submit"
        @click="submitInvitation"
        :disabled="isSubmit"
      >{{ $t("submitSure") }}</el-button>
    </div>
  </div>
</template>

<script>
import Storage from "@/utils/storage";

export default {
  name: "invitation-code",
  data() {
    return {
      // 当前输入框
      keyCode: 0,
      pasteFlag: false,
      inputCode: [{
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }],
      isSubmit: true,
      activeInput: 0,
      captchas: [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
      ],
    };
  },
  methods: {
    // 点击跳过按钮，跳转到供应商填写信息页面
    goCompanyCreat() {
      this.$router.replace({ path: '/company-create' });
    },
    // 自动校准输入顺序
    adjust(index) {
      const noneVal = this.captchas.find(element => element.num === '');
      let dom = document.getElementById("captcha" + this.activeInput);
      if (noneVal && index !== this.activeInput && dom) {
        dom.focus();
      } else {
        document.getElementById("captcha" + index).focus();
      }
    },
    // 控制前后方向
    inputDirection(index) {
      let val = this.captchas[index].num;
      // 回退键处理
      if (event.keyCode === 8 && val === "") {
        // 重新校准
        index = index === 0 ? 0 : index - 1;
        let dom = document.getElementById("captcha" + index);
        this.activeInput = index;
        if (dom) dom.focus();
      }
      if (event.keyCode !== 8 && val !== "") {
        let dom = document.getElementById("captcha" + (index + 1));
        this.activeInput = index + 1;
        if (dom) dom.focus();
      }
    },
    // 输入框相互联动
    inputFinash(index) {
      this.$set(this.captchas[index], "num", this.captchas[index].num.toUpperCase());

      let val = this.captchas[index].num;
      this.activeInput = val ? index + 1 : index - 1;
      let dom = document.getElementById("captcha" + this.activeInput);
      if (dom) dom.focus();
      // if (index === this.captchas.length - 1) {
      //   let code = this.captchas.map((x) => x.num).join("");
      //   if (code.length === 6) {
      //     this.$emit("finish", code);
      //   }
      // }
    },
    inputPause(index) {
      let dom = document.getElementById("captcha" + index);
    },
    submitInvitation() {
      this.isSubmit = true;
      let code = this.inputCode.map((value) => {
        return value.code;
      });
      if (code.join('').length !== 8) {
        return false;
      }
      this.$axios.post('/v1/supplier/bind-bo', {
        inviteCode: code.join('')
      }).then(result => {
        this.isSubmit = false;
        if (result.code === 0 || result.code === "0") {
          this.$router.replace({ path: "/company-create" });
        } else {
          this.$router.replace({ path: "/error-code" });
          // this.$message({ type: 'error', message: result.message });
        }
        this.inputCode = [{ code: "" }, { code: "" }, { code: "" }, { code: "" }, { code: "" }, { code: "" }, { code: "" }, { code: "" }];
      }).catch(err => {
        // if (err.toString() === "2002") {
        //   this.isSubmit = false;
        //   this.$router.replace({ path: "/error-code" });
        // }
        this.isSubmit = false;
      });
    },
    handleInput(index) {
      index = index < this.inputCode.length - 1 ? index : this.inputCode.length - 1;
      let nextIndex = index < this.inputCode.length - 1 ? index + 1 : this.inputCode.length - 1;
      if (this.keyCode === 8) {
        nextIndex = index;
        let dom = document.getElementById("testPaste" + (nextIndex - 1));
        if (dom) dom.focus();
        this.$set(this.inputCode[index], "code", "");
        return false;
      }
      // 粘贴的值 具体的处理方式
      if (this.pasteFlag) {
        if (this.inputCode[index].code.length > 1) {
          let codes = this.inputCode[index].code.replace(/\s*/g, "").toUpperCase();
          let codeArr = codes.split("");
          for (let i in this.inputCode) {
            this.$set(this.inputCode[i], "code", codeArr[i]);
          }
          let lashIndex = codes.length >= 8 ? 7 : codes.length;
          let dom = document.getElementById("testPaste" + lashIndex);
          if (dom) dom.focus();
        }
        this.pasteFlag = false;
      } else {
        if (this.inputCode[index].code.length > 1) {
          this.$set(this.inputCode[index], "code", this.inputCode[index].code.slice(1, 2).toUpperCase());
        } else {
          this.$set(this.inputCode[index], "code", this.inputCode[index].code.toUpperCase());
        }
        let dom = document.getElementById("testPaste" + nextIndex);
        if (dom) dom.focus();
      }
      let codeArr = this.inputCode.map((value) => {
        return value.code;
      });
      let code = codeArr.join('');
      console.log("code ", code);
      this.isSubmit = code.length !== 8;
    },
    handlePaste() {
      this.pasteFlag = this.inputCode.length === 8;
    },
    keyDown(index) {
      this.keyCode = event.keyCode;
      if (this.keyCode === 8 && (this.inputCode[index].code === undefined || this.inputCode[index].code === "")) {
        let dom = document.getElementById("testPaste" + (index - 1));
        if (dom) dom.focus();
        this.$set(this.inputCode[index], "code", "");
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.captcha_input_box {
  .el-input__inner {
    padding: 0;
    width: 74px;
    height: 74px;
    line-height: 74px;
    background: rgba(0, 0, 0, 0.01);
    margin-right: 12px;
    font-size: 60px;
    text-align: center;
    color: #000000;
    border: none;
  }
}
</style>
<style scoped lang="scss">
.invition-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.skip-btn {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding-right: 340px;
  margin-top: 20px;
  text-decoration: underline;
  color: #57dbd1;
  cursor: pointer;
}
.invitation-code {
  width: 100%;
  min-height: 500px;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 27px;
    font-family: "PingFangSC-Regular, PingFang SC";
    font-weight: 400;
    color: #000000;
    line-height: 38px;
  }
  .captcha_input_wrapper {
    width: 100%;
    margin-top: 41px;
    margin-bottom: 31px;
  }
  .captcha_input_box {
    width: 74px;
    height: 74px;
    line-height: 74px;
    background: rgba(0, 0, 0, 0.08);
    margin-right: 12px;
    font-size: 60px;
    text-align: center;
    color: #000000;
    border: none;
  }
  .btn-submit {
    width: 216px;
    font-size: 22px;
    font-family: "PingFangSC-Regular, PingFang SC";
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }
}
</style>